.container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-box {
    box-shadow: 0px 90.9961px 72.7969px rgba(41, 72, 152, 0.05), 0px 58.9789px 42.6334px rgba(41, 72, 152, 0.037963), 0px 35.0503px 23.1872px rgba(41, 72, 152, 0.0303704), 0px 18.1992px 11.8295px rgba(41, 72, 152, 0.025), 0px 7.4145px 5.9316px rgba(41, 72, 152, 0.0196296), 0px 1.68511px 2.86469px rgba(41, 72, 152, 0.012037);
    max-width: 492px;
    width: 95%;
    border-radius: 4px;
    height: fit-content;
    margin-top: 20px;
    backdrop-filter: blur(50px);
}

.return-address {
    display: flex;
    align-items: center;
    color: rgb(149, 149, 149);
    height: 45px;
    margin-top: 20px;
    cursor: pointer;
}

.return-address > input {
    background-color: transparent;
    cursor: pointer;
    border-style: none;
    color: inherit;
    outline: none;
    text-align: center;
    width: 100%;
    padding-left: 20px;
}

.footer-buttons {
    padding: 20px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.add-network {
    background-color: rgba(255, 255, 255, 0.15);
    color: darkgrey;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    border-radius: 25px;
    padding: 10px;
    min-width: 45%;
    height: 36px;
    border: 0px;
}

/* .add-network:hover {
    background-color: black;
    color: white;
} */

@media screen and (max-width: 500px) {
    .footer-buttons {
        padding: 20px 20px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        gap: 0px;
    }

    .footer-buttons > button {
        margin-top: 10px;
    }
}